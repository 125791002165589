@font-face {
  font-family: 'font-primary';
  src: url('../assets/fonts/Kanit-Regular.eot');
  src: url('../assets/fonts/Kanit-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Kanit-Regular.woff2') format('woff2'),
    url('../assets/fonts/Kanit-Regular.woff') format('woff'),
    url('../assets/fonts/Kanit-Regular.ttf') format('truetype'),
    url('../assets/fonts/Kanit-Regular.svg#svgFontName') format('svg');
}
body {
  font-family: 'font-primary', Fallback, sans-serif !important;
}
.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  background-color: #ececec;
  min-height: calc(100vh - 40px);
  width: 100%;
}
.container .body {
  display: flex;
  justify-content: center;
}
.container .body .menu,
.container .body .content {
  padding: 10px;
  width: 100%;
}
@media (min-width: 768px) {
  .container .body .menu,
  .container .body .content {
    width: 768px;
  }
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 10px;
  background-color: #ececec;
}
.footer img {
  margin-right: 10px;
}
.banner {
  width: 100%;
  height: 300px;
  background: #ec7646;
  background: linear-gradient(325deg, #ec7646 0%, orange 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 200px;
  padding: 10px;
  text-align: center;
  font-size: 16px;
}
.btn .label {
  color: black;
  font-weight: bold;
  margin-top: 10px;
}
.btn:hover {
  cursor: pointer;
  background-color: #fcfcfc;
}
.btn-call {
  width: 100%;
  background-color: #ec7646;
  text-align: center;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
}
.btn-call:hover {
  cursor: pointer;
}
.call {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #ec7646;
  color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  right: 10px;
  bottom: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.call:hover {
  cursor: pointer;
}
.flex-center {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  font-size: 20px;
}
.app-bar {
  background-color: #ec7646;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
}
.app-bar .app-bar-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.app-bar .search {
  width: 100%;
}
@media (min-width: 768px) {
  .app-bar .search {
    max-width: 500px;
  }
}
.app-bar h2 {
  font-weight: bold;
  color: white;
  margin-bottom: 0;
}
.app-bar-sticky {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: Center;
  padding: 10px;
  height: 60px;
  background-color: white;
  color: black;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
.app-bar-sticky .body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.app-bar-sticky .body .sub-title {
  white-space: nowrap;
  width: 500px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 768px) {
  .app-bar-sticky .body .sub-title {
    width: 100%;
  }
}
.app-bar-sticky .body .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .app-bar-sticky .body .title {
    font-size: 1rem !important;
  }
}
.show-sm {
  display: block !important;
}
@media (min-width: 768px) {
  .show-sm {
    display: none !important;
  }
}
.font-24 {
  font-size: 24px;
}
.font-bold {
  font-weight: bold;
}
.guide-item + .guide-item {
  margin-top: 10px;
}
.guide-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  color: black;
}
.guide-item:hover {
  cursor: pointer;
}
.guide-item .detail {
  margin-left: 10px;
  width: 100%;
}
.guide-item .detail .name-th {
  color: #747474;
}
.guide-item .guide {
  font-weight: bold;
  width: 100px;
  text-align: end;
}
.guide-green {
  width: 100%;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: green;
}
.card-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-image-list {
  display: flex;
  align-items: center;
  padding: 10px 30px 10px 10px;
  background-color: white;
  max-width: 500px;
}
.card-image-list:hover {
  cursor: pointer;
}
.card-image-list + .card-image-list {
  margin-top: 10px;
}
.guide {
  color: white;
  background-color: #ec7646;
  padding: 5px;
  width: fit-content;
}
.guide-title {
  color: blue;
  margin-top: 10px;
  font-size: 20px;
}
.smoke {
  color: #9e9e9e !important;
}
.w-100px {
  width: 100px;
}
.loading .ant-modal-content {
  background-color: transparent !important;
  box-shadow: none;
}
.header {
  text-align: center;
  background-color: blue;
  padding: 5px;
  color: white;
}
.icon {
  height: 20px;
  margin-right: 5px;
}
.card-call {
  background-color: white;
  padding: 10px;
}
.card-call .name {
  font-size: 18px;
  color: black;
  border-bottom: 1px #ececec solid;
  padding: 10px 0px;
}
.card-call .list {
  margin-top: 10px;
  padding-left: 10px;
}
.card-call .list .item + .item {
  margin-top: 20px;
}
.card-call .list .item {
  display: flex;
  align-items: center;
}
.card-call .list .item .icon {
  width: 30px;
  height: 30px;
}
.card-call .list .item .icon img {
  width: 100%;
}
.card-call .list .item a {
  font-size: 17px;
  color: #0170fe;
  text-decoration: none;
}
.card-call + .card-call {
  margin-top: 10px;
}
.document-item {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  color: black;
}
.document-item .ant-list-item:hover {
  cursor: pointer;
}
.green-table-card {
  background-color: white;
  padding: 10px;
}
.green-table-card h2 {
  color: black;
}
.green-table-card + .green-table-card {
  margin-top: 20px;
}
.ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-tab:hover {
  color: #ec7646 !important;
}
.ant-tabs-ink-bar {
  background: #ec7646 !important;
}
.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  background: #ec7646 !important;
  color: white !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.ant-table-thead > tr > th {
  background: #8bc34a !important;
}
.ant-radio-group {
  width: 100% !important;
}
.App-map {
  height: 89.4vh;
  width: 100%;
}
.btn-option {
  position: absolute;
  padding: 5px 10px;
  bottom: 70px;
  z-index: 100;
  left: 0px;
  cursor: pointer;
}
@media (max-device-width: 600px) {
  .btn-option {
    bottom: 0px;
  }
}
